import React, { useState, useEffect } from "react";
import Question from "./Question";
import Progress from "./Progress";
import Recommendations from "./Recommendations";
import initImageCheck from "../../components/imageCheck";

import { loadRecommendations } from "../service/recommendations";

const Quiz = ({ questions }) => {
  const [recommendations, setRecommendations] = useState(null);
  const [showStartSlide, setShowStartSlide] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [finished, setFinished] = useState(false);
  const [resultLoading, setResultLoading] = useState(false);


  const submit = (value, key, hold) => {
    const newAnswers = { ...answers, [key]: value };

    if(hold) {
      setAnswers(newAnswers);
      return;
    }

    let newIndex = activeIndex + 1;
    let nextQuestion = questions[newIndex];

    if(!nextQuestion) {
      setAnswers(newAnswers);
      setFinished(true);
    } else {
      let { condition } = nextQuestion;
      while (condition && !condition.value.includes( newAnswers[condition.key])) {
        newIndex++;
        nextQuestion = questions[newIndex];
        condition = nextQuestion && nextQuestion.condition;
      }
      setActiveIndex(newIndex);
      setAnswers(newAnswers);
    }
  };
  

  const back = () => {
    let newIndex = activeIndex - 1;
    let nextQuestion = questions[newIndex];
    let { condition } = nextQuestion;
    while (condition && !condition.value.includes( answers[condition.key])) {
      newIndex--;
      nextQuestion = questions[newIndex];
      condition = nextQuestion && nextQuestion.condition;
    }

    setActiveIndex(Math.max(0, newIndex));
  };

  const reset = () => {
    setActiveIndex(0);
    setFinished(false);
    setAnswers({});
    setRecommendations(null);
    setShowStartSlide(false)
  }

  const question = questions[activeIndex];

  useEffect(() => {
    if (!finished) {
      return;
    }
    setResultLoading(true);
  
    const fetchRecommendations = async () => {
      const recommendations = await loadRecommendations(answers);
      setRecommendations(recommendations);
      setResultLoading(false);

      // Replace not loaded or corrupted images
      setTimeout(() => {
          initImageCheck();
      }, 500);
    };
  
    fetchRecommendations();
  }, [finished]);

  return (
    <div className="quiz">
      <div className="quizContainer">
        
        {showStartSlide && 
          <div>
            <h1>Take the quiz!</h1>
            <h3>Our RV Matchmaker™ tool​ is designed to help you discover your perfect RV.</h3>
            <button onClick={reset} className="red-btn quiz-button">Start</button>
          </div>
        }

        {resultLoading &&
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div className="loader" role="status"></div>
            <span className="loader-text">Loading...</span>
          </div>
        }

        {recommendations && 
          <Recommendations
            template={recommendations.template}
            searchUrl={recommendations.searchUrl}
            resultText={recommendations.resultText}
          ></Recommendations>
        }
        
        {!showStartSlide && !resultLoading && !recommendations && <Question
            key={question.key}
            question={question}
            questionAnswer={answers[question.key]}
            answers={answers}
            onSubmit={submit}
          ></Question>}
      </div>
      <div className="quiz-footer">
        {recommendations ? null : (
          <>
            <Progress percentage={(activeIndex / questions.length) * 100} />
            <button onClick={back} className="red-btn quiz-button">
              Back
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Quiz;
