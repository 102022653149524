import React, { useState, useEffect } from "react";

const TileButton = ({
    styles,
    singleSelect,
    onSubmit,
    setSelectedValues,
    text,
    image,
    value,
    keyName,
    selectedValues
}) => {
    const isSelected = !!selectedValues.find(v => v == value);
    const [selected, setSelected] = useState(isSelected);
    const toggleSelection = value => {        
        setSelectedValues(prevSelectedValues => 
            prevSelectedValues.includes(value)
              ? prevSelectedValues.filter(v => v !== value)
              : [...prevSelectedValues, value]
          );
          setSelected(!selected);
    };

    useEffect(() => {
        const preloadImage = new Image();
        preloadImage.src = window.quizImages[image];
    }, [image]);

    return (
    <div className={`image-text ${selected ? 'selected' : ''}`} style={styles} onClick={() => singleSelect ? onSubmit(value, keyName) : toggleSelection(value)} >
        <img src={window.quizImages[image]} alt={text} loading="eager"/>
        <div className="text-center">{text}</div>          
    </div>
  );
};

export default TileButton;
