import React, { useState, useEffect } from "react";

const TileButton = ({
    styles,
    singleSelect,
    onSubmit,
    setSelectedValues,
    supportText,
    text,
    image,
    value,
    keyName,
    selectedValues
}) => {
    const isSelected = !!selectedValues.find(v => v == value);
    const [selected, setSelected] = useState(isSelected);
    const toggleSelection = value => {        
        setSelectedValues(prevSelectedValues => 
            prevSelectedValues.includes(value)
              ? prevSelectedValues.filter(v => v !== value)
              : [...prevSelectedValues, value]
          );
          setSelected(!selected);
    };

    useEffect(() => {
        const preloadImage = new Image();
        preloadImage.src = window.quizImages[image];
    }, [image]);

    return (
    <div style={styles} className={`tile ${selected ? 'selected' : ''}`} onClick={() => singleSelect ? onSubmit(value, keyName) : toggleSelection(value)} >
        <div className="text-center main-text">{text}</div>
        <img src={window.quizImages[image]} alt={text} />
        {supportText ? <div className="text-center support-text">{supportText}</div> : null}
    </div>
  );
};

export default TileButton;
