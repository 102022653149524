export const loadRecommendations = async (data) => {
    const response = await fetch(window.quizUrls.loadData, {
        body: JSON.stringify(data),
        method: "POST",
        headers: {
            "Content-Type": "application/json",
          }
    });

    const result = await response.text();

    return {
        template:result,
        searchUrl: response.headers.get('X-Sf-CC-search-url'),
        resultText: response.headers.get('X-Sf-CC-result-text')
    };
};