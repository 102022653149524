import React from "react";

const Progress = ({percentage}) => {

    const style = {
      width: percentage + '%'
    };
  
    return (
      <div className="progress-bar-container">
        <span>{percentage.toFixed(0)}%</span>
        <div className="progress-bar">
          <span style={style}></span>
        </div>
      </div>
    );
};

export default Progress;