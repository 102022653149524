import React, { useState, useEffect } from "react";
import QuizOption from "./QuizOption";

const Question = ({ question, questionAnswer, answers, onSubmit }) => {
  const { mainHeader, subHeader, type, key, options, showSkip, showSubmit, singleSelect, customInput } = question;
  const [answer, setAnswer] = useState(questionAnswer || '');
  const [isButtonDisabled, setIsButtonDisabled] = useState(answer === '');

  const selectAnswer = (a) => {
      setIsButtonDisabled(a.trim() === '');
      setAnswer(a);
  };

  return (
    <div>
      <h1>{mainHeader}</h1>
      {subHeader ? <h3>{subHeader}</h3> : null}
      <div className="answers">
        <QuizOption
          type={type}
          options={options || null}
          selectAnswer={selectAnswer}
          onSubmit={onSubmit}
          keyName={key}
          singleSelect={singleSelect}
          answer={answer}
          answers={answers}
          customInput={customInput}
          setIsButtonDisabled={setIsButtonDisabled}
          ></QuizOption>
      </div>
      {(showSkip) ?
        <button className="quiz-button" onClick={() => onSubmit('', key)}>I’m not sure yet, let’s skip it.</button> : 
        null }
      {showSubmit ?
          <button className="quiz-button" disabled={isButtonDisabled} onClick={() => onSubmit(answer, key)}>Submit</button> :
          null
      }
    </div>
  );
};

export default Question;
